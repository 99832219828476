








































import { defineComponent } from '@nuxtjs/composition-api';
import { SfImage } from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';
import { getStoryblokLink } from '~/modules/storyblok/helpers/getStoryblokLink';
import { resolveUrl } from '~/modules/storyblok/helpers/resolveUrl';

export default defineComponent({
  components: {
    SfImage,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      addBasePath,
      getStoryblokLink,
      resolveUrl,
    };
  },
});
